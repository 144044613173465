div[role="tooltip"] {
	z-index: 1300;
}

body > div[role="presentation"]:nth-child(1) {
	z-index: 1301;
}
body > div[role="presentation"]:nth-child(2) {
	z-index: 1302;
}
body > div[role="presentation"]:nth-child(3) {
	z-index: 1303;
}
body > div[role="presentation"]:nth-child(4) {
	z-index: 1304;
}
body > div[role="presentation"]:nth-child(5) {
	z-index: 1305;
}
body > div[role="presentation"]:nth-child(6) {
	z-index: 1306;
}
body > div[role="presentation"]:nth-child(7) {
	z-index: 1307;
}
body > div[role="presentation"]:nth-child(8) {
	z-index: 1308;
}
body > div[role="presentation"]:nth-child(9) {
	z-index: 1309;
}

.phone-view tr.dx-header-row {
	display: none;
}

.dx-datagrid-search-panel .dx-texteditor-input{
	text-align: left;
    font-size: 0.8rem;
}

.loading-container {
	pointer-events: 'none'; 
	opacity: 0.2 
}

ul.phone-view .MuiMenuItem-dense {
    font-size: small;
}

DIV.freespaced-table TR.dx-freespace-row {
	display:block !important;
	min-height:50px;
	width:1px;
}

.phone-view .MuiTableCell-root {
    padding: 4px 0px 4px 4px;
}

.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ellipsis {
	white-space: break-spaces !important;
	text-overflow: ellipsis;
	max-width: 100%;
	display: block !important;
	overflow: hidden;
}

#release-notes-description.desktop-view.dx-htmleditor {
    height: 100% !important;
}

#release-notes-description.phone-view.dx-htmleditor {
    height: 500px !important;
}

.list-element-space + .list-element-space {
	margin-top: 10px
}
